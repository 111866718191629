<template>
<div style="background-color:#e4e4e4" class="pa-1">
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="filteredGridRows"
    :search="search"
    class="elevation-1"
    dense
    fixed-header
    height="60vh"
    :items-per-page="100"
    :footer-props="footerProps"
    :loading="loadTable"
    loading-text="Ачааллаж байна... Түр хүлээнэ үү!"
    no-data-text="Мэдээлэл байхгүй байна."
    no-results-text="Тохирох үр дүн олдсонгүй. Та дахин хайна уу?"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>ОРЛОГЫН ДЭЛГЭРЭНГҮЙ ТАЙЛАН</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-col>
          <tr>
            <td>{{org_nm}}</td>
          </tr>
          <tr>
            <td><small>(Аж ахуйн нэгжийн нэр)</small></td>
          </tr>
        </v-col>
        
        <v-col cols="12" sm="3">
          <tr>
            <td><small>Тайлант үе : {{dateRangeText}}</small></td>
          </tr>
          <tr>
            <td align="right"><small>/төгрөгөөр/</small></td>
          </tr>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Хайлт"
            rounded
            filled
            background-color="blue lighten-5"
            clearable
            hide-details
          ></v-text-field>
        </v-col> -->

        <v-switch
          v-model="isActive"
          label="Hide (8,81,30,300)"
          color="success"
          value="true"
          class="mb-2 mr-2"
          hide-details
        ></v-switch>

        <!-- Print (start) -->
        <v-btn
          color="blue lighten-3"
          dark
          class="mb-2 mr-2"
          @click="wholePrint()"
        >
          PDF <i class="mdi mdi-printer" aria-hidden="true"></i>
        </v-btn>
        <!-- Print (end) -->

        <!-- Export (start) -->
        <vue-json-csv
          class="btn btn-default"
          :data="filteredGridRows2"
          :fields="export_fields"
          :labels="export_labels"
          name="report_income.csv"
        >
          <v-btn color="error lighten-2" dark class="mb-2 mr-2">
            Export <i class="mdi mdi-export-variant" aria-hidden="true"></i>
          </v-btn>
        </vue-json-csv>
        <!-- Export (end) -->

      </v-toolbar>
    </template>

    <template v-slot:item.amnt1="{ item }">
      <span>{{formatPrice(item.amnt1)}}</span>
    </template>
    <template v-slot:item.amnt2="{ item }">
      <span>{{formatPrice(checkAmnt(item, 2))}}</span>
    </template>

    <!-- <template v-slot:body.append>
      <tr class="bold--text">
        <th />
        <th class="text-left">НИЙТ ДҮН</th>
        <th class="text-right">{{ formatPrice(sumField('amnt1')) }}</th>
        <th class="text-right">{{ formatPrice(sumField('amnt2')) }}</th>
      </tr>
    </template> -->

    <template v-slot:no-data>
    </template>
  </v-data-table>
</div>
</template>

<script>
  import axios from 'axios';
  import jsPDF from 'jspdf';
  import {_ARIAL, _ARIAL_BOLD, _ARIAL_ITALIC, _ARIAL_BOLD_ITALIC} from '/src/assets/constant/constant.js'
  import common from '/src/assets/common/common.js'

  let bgColorCode = "orange lighten-5";
  export default {
    data: () => ({
      footerProps: {'items-per-page-options': [10, 20, 50, 100, -1]},
      search: '',
      selected: [],
      orgs: [],
      loadTable: true,
      org_nm: '',
      isActive: false,
      date_range: ['', ''],
      headers: [
        { text: 'Мөрийн дугаар', value: 'notes', divider: true, sortable: false, class: bgColorCode, width: '120px' },
        { text: 'Үзүүлэлт', value: 'name', divider: true, sortable: false, class: bgColorCode },
        { text: 'Эхний үлдэгдэл', value: 'amnt1', divider: true, sortable: false, align: 'right', class: bgColorCode },
        { text: 'Тайлант үеийн үлдэгдэл', value: 'amnt2', divider: true, sortable: false, align: 'right', class: bgColorCode },
      ],
      export_fields: [
        'notes', 'name', 'amnt1', 'amnt2'
      ],
      export_labels: {
        notes: 'Мөрийн дугаар',
        name: 'Үзүүлэлт',
        amnt1: 'Эхний үлдэгдэл',
        amnt2: 'Тайлант үеийн үлдэгдэл'
      },
      filters: {
        notes: [],
				name: [],
			},
      gridRows: [],
      gridOrgRows: [],
    }),

    computed: {
      filteredGridRows() {
				return this.gridRows.filter((d) => {
					return Object.keys(this.filters).every((f) => {
						// return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
            return this.isActive ? (d.code != 201072 && d.code != 201073 && d.code != 201078 && d.code != 201079) && (this.filters[f].length < 1 || this.filters[f].includes(d[f])) : (this.filters[f].length < 1 || this.filters[f].includes(d[f]));
					});
				});
			},
      filteredGridRows2() {
        return this.filteredGridRows.map(obj => {
              let rObj = {"code":obj.code,"notes":obj.notes,"name":obj.name,"da_amnt":obj.da_amnt,"ca_amnt":obj.ca_amnt,
                          "dc_amnt":obj.dc_amnt,"cc_amnt":obj.cc_amnt,"dl_amnt":obj.dl_amnt,"cl_amnt":obj.cl_amnt,
                          "amnt1":obj.amnt1,"amnt2":this.checkAmnt(obj, 2)}
              return rObj
        });
			},
      dateRangeText () {
        return this.date_range.join(' ~ ')
      },
    },

    watch: {
    },

    created () {
      this.getOrgInfo()
      this.initialize()
    },

    methods: {
      initialize () {
        this.org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        this.date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let user_org = JSON.parse(sessionStorage.getItem('user_org'))
        let user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt
        let parent_code = '201'
        let rprt_code = '301002'
        const params = {
          org_id: { toJSON: () => user_org.id },
          s_dt: user_dt[0],
          f_dt: user_dt[1],
          p_cd: parent_code,
          r_cd: rprt_code
        };
        // axios.get('journal/balance', { params })
        //   .then(response => {
        //     this.gridRows = response.data;
        //     this.loadTable = false;
        //     // console.log(JSON.stringify(this.gridRows));
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   });
        axios.get('commoncode/accts_rprt_code', { params })
          .then(response => {
            this.gridRows = response.data;
            this.gridOrgRows = response.data;
            this.loadTable = false;
            // console.log(JSON.stringify(this.gridRows));
          })
          .catch(error => {
            console.log(error)
          });
      },

      getOrgInfo () {
        let userId = JSON.parse(sessionStorage.getItem('user')).id
        axios.get('org/by_user/' + userId)
          .then(response => {
            this.orgs = response.data;
          })
          .catch(error => {
            console.log(error)
          }
        )
      },

      formatPrice(value) {
        return value == null || value == "0" ? "-" : Number(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },

      totalBalance(item, type) {
        return type == 1 ? (Number(item.debit_amnt)+Number(item.credit_amnt)) : 0
      },

      checkAmnt(item, type) {
        if (item.code == '201066') {
          return this.filteredGridRows.filter(f => Number(f.code) == 201064).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0) - this.filteredGridRows.filter(f => Number(f.code) == 201065).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201071') {
          return this.gridOrgRows.filter(f => Number(f.code) > 201071 && Number(f.code) < 201074).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201077') {
          return this.gridOrgRows.filter(f => Number(f.code) > 201077 && Number(f.code) < 201080).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201085') {
          return this.filteredGridRows.filter(f => Number(f.code) == 201064).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201065).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.gridOrgRows.filter(f => Number(f.code) > 201066 && Number(f.code) < 201074).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.gridOrgRows.filter(f => Number(f.code) >= 201074 && Number(f.code) <= 201079).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) >= 201080 && Number(f.code) <= 201084).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        // added by 23.02.01 (19deh murnii bodolt deer 1 murnuus 14,15 murnii utgiig nemj hasaad tax rate.r urjuulsen dung nemj gargana, 21 onii tailan deer oorchlolt oruulahgui bh)
        } else if (item.code == '201086') {
          var org_id = JSON.parse(sessionStorage.getItem('user_org')).id;
          var org = this.orgs.filter(x=> x.id==org_id)[0];
          var user_dt = JSON.parse(sessionStorage.getItem('user_dt')).dt;
          // edited by 23.02.03 (bodoltiig xls deer amra gargaj ogson)
          // (1+4+6+7+8+14+15-2-9-10-11-30)*.0.1
          // (201064+201067+201069+201070+201072+201081+201082 - 201065-201074-201075-201076-201078) * 0.1
          // edited on 25.01.21
          // let tVal = ((this.gridOrgRows.filter(f => Number(f.code) == 201081 || Number(f.code) == 201082).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)) * Number(org.corp_inco_tax_rate) / 100);
          // let tVal = ((this.gridOrgRows.filter(f => Number(f.code) == 201064 || Number(f.code) == 201067
          //  //|| Number(f.code) == 201069 || Number(f.code) == 201070 
          //  || Number(f.code) == 201072 || Number(f.code) == 201081
          //  || Number(f.code) == 201082).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
          //  - this.gridOrgRows.filter(f => Number(f.code) == 201065 || Number(f.code) == 201074 || Number(f.code) == 201075 || Number(f.code) == 201076
          //  || Number(f.code) == 201078).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)) * Number(org.corp_inco_tax_rate) / 100);
          // // let iVal = this.gridOrgRows.filter(f => Number(f.code) == 201068).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0) * Number(org.intr_inco_tax_rate) / 100;
          // // return Number(user_dt[1].substring(0, 4)) > 2021 ? (tVal < 0 ? iVal : iVal + tVal) : iVal;
          // edited on 25.01.21
          // return Number(user_dt[1].substring(0, 4)) > 2021 ? item.amnt2 + tVal : item.amnt2;
          return item.amnt2;
          // return Number(user_dt[1].substring(0, 4)) > 2021 ? (tVal < 0 ? item.amnt2 : item.amnt2 + tVal) : item.amnt2;
        } else if (item.code == '201087') {
          return this.filteredGridRows.filter(f => Number(f.code) == 201064).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201065).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.gridOrgRows.filter(f => Number(f.code) > 201066 && Number(f.code) < 201074).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.gridOrgRows.filter(f => Number(f.code) >= 201074 && Number(f.code) <= 201079).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) >= 201080 && Number(f.code) <= 201084).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201086).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201089') {
          return this.filteredGridRows.filter(f => Number(f.code) == 201064).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201065).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.gridOrgRows.filter(f => Number(f.code) > 201066 && Number(f.code) < 201074).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.gridOrgRows.filter(f => Number(f.code) >= 201074 && Number(f.code) <= 201079).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) >= 201080 && Number(f.code) <= 201084).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201086).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) == 201088).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201090') {
          return this.filteredGridRows.filter(f => Number(f.code) >= 201091 && Number(f.code) <= 201093).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else if (item.code == '201094') {
          return this.filteredGridRows.filter(f => Number(f.code) == 201064).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201065).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.gridOrgRows.filter(f => Number(f.code) > 201066 && Number(f.code) < 201074).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.gridOrgRows.filter(f => Number(f.code) >= 201074 && Number(f.code) <= 201079).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) >= 201080 && Number(f.code) <= 201084).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            - this.filteredGridRows.filter(f => Number(f.code) == 201086).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) == 201088).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0)
            + this.filteredGridRows.filter(f => Number(f.code) >= 201091 && Number(f.code) <= 201093).reduce((a, b) => Number(a) + (Number(b[type == 1 ? 'amnt1' : 'amnt2']) || 0), 0);
        } else {
          return type == 1 ? Number(item.amnt1) : Number(item.amnt2);
        }
      },

      columnValueList(val) {
				return this.gridRows.map((d) => d[val]);
			},

      sumField(key) {
        // sum data in give key (property)
        return this.filteredGridRows.reduce((a, b) => Number(a) + (Number(b[key]) || 0), 0)
      },

      wholePrint() {
        if (this.filteredGridRows2.length == 0) { return alert('Мэдээлэл байхгүй байна.'); }
        // include autotable library
        require('jspdf-autotable');
        var org_nm = JSON.parse(sessionStorage.getItem('user_org')).name
        var org_id = JSON.parse(sessionStorage.getItem('user_org')).id
        var org = this.orgs.filter(x=> x.id==org_id)[0]
        var date_range = JSON.parse(sessionStorage.getItem('user_dt')).dt

        // create jspdf constant
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4"
        });

        // Add mongolian font (Arial-Mon)
        doc.addFileToVFS('Arial-Mon-normal.ttf', _ARIAL);
        doc.addFileToVFS('Arial-Mon-bold.ttf', _ARIAL_BOLD);
        doc.addFileToVFS('Arial-Mon-italic.ttf', _ARIAL_ITALIC);
        doc.addFont('Arial-Mon-normal.ttf', 'Arial-Mon', 'normal');
        doc.addFont('Arial-Mon-bold.ttf', 'Arial-Mon', 'bold');
        doc.addFont('Arial-Mon-italic.ttf', 'Arial-Mon', 'italic');

        // set report title on top
        doc.setFont("Arial-Mon", "bold").setFontSize(12).text('ОРЛОГЫН ДЭЛГЭРЭНГҮЙ ТАЙЛАН', 140, 30);
        // text is placed using x, y coordinates (landscape)
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(org_nm, 20, 60);
        doc.setFont("Arial-Mon", "normal").setFontSize(10).text(date_range.join(' ~ '), 340, 60);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('( Аж ахуйн нэгжийн нэр )', 20, 67);
        doc.setFont("Arial-Mon", "normal").setFontSize(8).text('/ төгрөгөөр /', 340, 67);
        
        // set headers and data for autoTable
        const headers2 = [
          ['Мөрийн дугаар', 'Үзүүлэлт', 'Эхний үлдэгдэл', 'Тайлант үеийн үлдэгдэл']
        ];
        let data2 = [];
        this.filteredGridRows2.forEach(function (e) {
          var ids = [];
          ids.push(e.notes);
          ids.push(e.name);
          ids.push(common.moneyFormat(e.amnt1));
          ids.push(common.moneyFormat(e.amnt2));
          data2.push(ids);
        });

        if (data2.length == 0) { data2.push(['Мэдээлэл байхгүй байна.']) }

        // Using autoTable plugin
        doc.autoTable({ head: headers2, headStyles: { fillColor: 'white', lineWidth: 0.1, lineColor: 'gray', halign: 'center', textColor: 'black'},
                        body: data2, tableLineWidth: 0.75, theme: "grid",
                        columnStyles: { 2: { halign: 'right' }, 3: { halign: 'right' } },
                        margin: { left: 20, top: 70, right: 20 }, styles : { font : 'Arial-Mon', fontStyle :'normal', fontSize : 9} })
        let finalY = doc.lastAutoTable.finalY;
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Захирал: ............................. / ' + org.ceo_firstname + ' /', 160, finalY+30);
        doc.setFont("Arial-Mon", "normal").setFontSize(9).text('Нягтлан бодогч: .................. / ' + org.acct_firstname + ' /', 160, finalY+45);
        
        // saving file
        doc.save(`report_income.pdf`);
      },

    },
  }
</script>